import MinorApplicationService from "@/services/MinorApplicationService";
import downloadFile from '@/helpers/downloadFile'
import i18n from '@/plugins/i18n.js'
import Vue from "vue";
import qs from "qs"

export async function exportExcel(params) {
    const config = {
        params,
        paramsSerializer: (params) => qs.stringify(params, { encode: false })
    };
    
    const response = await MinorApplicationService.excelExport(config)
        .catch(e => Vue.$toast.error(i18n.t('api.' + e.data.message)))

    if (response.status === 200) {
        downloadFile(response, `${i18n.t('minor_applications')} ${i18n.t('report')}.xlsx`)
    }
    Vue.$toast.success(i18n.t('downloaded'));
}